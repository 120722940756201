import React from 'react';

// eslint-disable-next-line no-undef
const sourceUrl = import.meta.env.VITE_APP_SOURCE_URL;

const SourceLink = props => {
  return (
    <a href={sourceUrl} rel="noopener noreferrer" {...props} />
  );
};

export default SourceLink;
import React, { useState, useEffect, useContext } from "react";

import AuthPage from "./pages/AuthPage";
import AuthContext from "./context/authContext";
import useCognito from "./hooks/useCognito";
import { BackendStatusProvider } from "./context/backendStatus/backendStatusContext";
import ErrorBoundary from "./components/ErrorBoundary"
import Router from "./components/Router";
import UpdateModal from "./components/UpdateModal/UpdateModal";

import useIdleTimer from "./hooks/useIdleTimer";
import useActivityMonitor from "./hooks/useActivityMonitor";
import { CssBaseline } from "@mui/material";
import PageSpinner from "./components/PageSpinner";
import { useAppContext } from "./context/appContext";
import BasicSnackbar from "./components/common/BasicSnackbar/BasicSnackbar";
import { ThemeProvider } from "./context/ThemeContext";
import { ScreenResolutionProvider } from "./context/screenResolutionContext";


// eslint-disable-next-line no-undef
const serverUri = import.meta.env.VITE_APP_SERVER_URI;
// eslint-disable-next-line no-undef
const apiVersion = import.meta.env.VITE_APP_AUTH_SERVICE_API_VERSION;

export default function App() {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [userData, setUserData] = useState({ email: "", groups: [], firstName: "", lastName: "" });
	const { getAuthenticatedUser, signOut } = useCognito();
	const [csrfToken, setCsrfToken] = useState(null);
	const [isLoading, setIsLoading] = useState(true); // New loading state
	const { isAcquireRunning } = useAppContext();

	const onIdle = () => {
		// If the user is authenticated and no data acquisition is running, sign out due to inactivity
		if (isAuthenticated && !isAcquireRunning) {
			setIsAuthenticated(false);
			signOut();
		}
	};

	const keepAliveAction = async () => {
		// Skip keep-alive action if data acquisition is running
		if (isAcquireRunning) {
			return;
		}
		try {
			const response = await fetch(
				`${serverUri}/${apiVersion}/keep-alive`,
				{
					method: "POST",
					credentials: "include",
					headers: {
						"Content-Type": "application/json; charset=utf-8",
						"Cache-Control": "no-cache, no-store, must-revalidate",
						"X-CSRF-Token": localStorage.getItem("csrfToken"),
					},
				}
			);

			if (!response.ok) {
				const errorResponse = await response.json(); // Assuming error details are also sent as JSON
				throw new Error(`Error: ${errorResponse.message || response.statusText}`);
			}
		} catch (error) {
			console.error(error.message);
		}
	};

	const idleDuration = 60 * 60 * 1000;
	const keepAliveInterval = 10 * 1000;

	useIdleTimer(idleDuration, onIdle);
	useActivityMonitor(idleDuration, onIdle, keepAliveInterval, keepAliveAction);

	useEffect(() => {
		const checkAuthentication = async () => {
			try {
				const authenticatedUser = await getAuthenticatedUser();
				if (authenticatedUser) {
					setIsAuthenticated(true);
				} else {
					setIsAuthenticated(false);
				}
			} catch (error) {
				setIsAuthenticated(false);
			} finally {
				setIsLoading(false); // Set loading to false when authentication check is done
			}
		};
		checkAuthentication(); //load user

		return () => {
		};
	}, [getAuthenticatedUser]);


	useEffect(() => {
		// Load initial data from localStorage
		const email = localStorage.getItem("userEmail") || "";
		const groups = localStorage.getItem("groups") || [];
		const firstName = JSON.parse(localStorage.getItem('firstName'));
		const lastName = JSON.parse(localStorage.getItem('lastName'));
		setUserData({ email, groups, firstName, lastName });
	}, []);
	return (
		<AuthContext.Provider
			value={{ isAuthenticated, setIsAuthenticated, userData, setUserData, csrfToken, setCsrfToken }}>
			<ScreenResolutionProvider>

				<ThemeProvider>
					<CssBaseline />
					<BackendStatusProvider>
						<BasicSnackbar />
						<ErrorBoundary>
							{isLoading ? (
								<PageSpinner />
							) : !isAuthenticated ? (
								<AuthPage hideSighUp={true} />
							) : (
								<>
									<UpdateModal />
									<Router />
								</>
							)}
						</ErrorBoundary>
					</BackendStatusProvider>
				</ThemeProvider>
			</ScreenResolutionProvider>
		</AuthContext.Provider>
	);
};
import AuthForm from "../components/AuthForm";
import React from "react";
import { Card, Grid } from "@mui/material";
import appInfo from "../../package.json";

const AuthPage = () => {
	// eslint-disable-next-line no-undef
	const env = import.meta.env.VITE_APP_ENV;
	const shouldDisplayVersion = env === "develop" || env === "testing";
  
	return (
		<Grid container spacing={0} alignItems="center" justifyContent="center" style={{ minHeight: "100vh" }}>
			<Grid item xs={8} sm={8} md={6} lg={6} style={{ maxWidth: "500px" }}>
				<Card variant="outlined" style={{ padding: "2rem" }}>
					<AuthForm />
				
				</Card>
				<div style={{ marginTop: "2rem", textAlign: "center" }}>
					{shouldDisplayVersion && (
						<div
							style={{
								marginTop: "1rem",
								textAlign: "center",
								fontSize: "0.75rem",
								color: "grey",
							}}
						>
							v{appInfo.version}
						</div>
					)}
				</div>
			</Grid>
		</Grid>
	);
};

export default AuthPage;

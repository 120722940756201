import {
	SET_CERT_VALID,
	SET_CERT_INVALID,
	SET_BACKEND_AVAILABLE,
	SET_BACKEND_UNAVAILABLE,
	SET_BACKEND_VERSION,
	SET_FRONTEND_VERSION,
	SET_UPDATE_REQUESTED,
	SET_UPDATING,
	SET_UPDATE_FINISHED,
	FLUSH_UPDATE_DATA,
	FETCH_COMPATIBLE_VERSIONS,
	FETCH_COMPATIBLE_VERSIONS_SUCCESS,
	FETCH_COMPATIBLE_VERSIONS_FAILURE,
	SET_SAVED_TARGET_VERSION,
} from "./backendStatusActions";

import appInfo from "../../../package.json";
import { processCompatibleVersionsData } from "../../utils/backendUtils";

const reducer = (state, action) => {
	switch (action.type) {
		case SET_CERT_VALID:
			return {
				...state,
				isCertificateValid: true,
			};
		case SET_CERT_INVALID:
			return {
				...state,
				isCertificateValid: false,
			};
		case SET_BACKEND_AVAILABLE:
			return {
				...state,
				isAvailable: true,
			};
		case SET_BACKEND_UNAVAILABLE:
			return {
				...state,
				isAvailable: false,
			};
		case SET_BACKEND_VERSION:
			// Check if action.payload is defined
			if (!action.payload) {
				console.error("Undefined payload for SET_BACKEND_VERSION");
				return { ...state, isFetchingCompatibleVersions: false };
			}
			return {
				...state,
				version: action.payload.version,
			};
		case SET_FRONTEND_VERSION:
			return {
				...state,
				frontendVersion: appInfo.version, // Update the frontend version in the state
			};
		case FETCH_COMPATIBLE_VERSIONS:
			return {
				...state,
				isFetchingCompatibleVersions: true,
				compatibleVersionsError: null,
			};
		case FETCH_COMPATIBLE_VERSIONS_SUCCESS: {
			if (!action.payload.data) {
				return {
					...state,
					isFetchingCompatibleVersions: false,
					fetchVersionsError: "No data received",
				};
			}

			const currentBackendVersion = state.version;
			const currentFrontendVersion = appInfo.version;
			const savedCompatibility = localStorage.getItem("compatibility");
			const isPreReleaseTester = action.payload.isPreReleaseTester;

			const {
				isBackendCompatibleWithFrontend,
				closestNextBackendVersion,
				isUpdateRequired,
				isUpdatePossible,
			} = processCompatibleVersionsData(action.payload.data, currentBackendVersion, currentFrontendVersion, savedCompatibility, isPreReleaseTester);

			return {
				...state,
				isBackendCompatibleWithFrontend,
				compatibleVersions: action.payload.data,
				targetVersion: closestNextBackendVersion,
				isUpdateRequired,
				isUpdatePossible,
				isFetchingCompatibleVersions: false,
			};
		}
		case FETCH_COMPATIBLE_VERSIONS_FAILURE:
			return {
				...state,
				compatibleVersionsError: action.payload,
				isFetchingCompatibleVersions: false,
				data: action.payload.data,
				error: action.payload.error,
			};
		case SET_UPDATE_REQUESTED:
			return {
				...state,
				isUpdateRequested: true,
			};
		case SET_UPDATING:
			return {
				...state,
				isUpdateRequested: true,
				isUpdating: true,
			};
		case SET_SAVED_TARGET_VERSION:
			return {
				...state,
				savedTargetVersion: state.targetVersion, // Set the next available version
			};
		case SET_UPDATE_FINISHED:
			return {
				...state,
				isUpdateRequested: false,
				isUpdating: false,
				updateMessage: {
					message: action.payload.message,
					status: action.payload.status,
				},
			};
		case FLUSH_UPDATE_DATA:
			return {
				...state,
				updateMessage: {
					message: "",
					status: "",
				},
			};
		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};

export default reducer;

import PropTypes from "prop-types";
import React from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "./Typography";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "100%",
		width: "100%",
	},
}));

const PageSpinner = ({ color = "primary" }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<CircularProgress color={color} />
			<Typography variant="h5" component="div"></Typography>
		</div>
	);
};

PageSpinner.propTypes = {
	color: PropTypes.oneOf(["primary", "secondary", "error", "warning", "info", "success"]),
};

export default PageSpinner;

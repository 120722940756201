import React, { useContext, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useBackendStatusContext } from '../context/backendStatus/backendStatusContext';
import { MainLayout } from './Layout';
import PageSpinner from './PageSpinner';
import AuthContext from '../context/authContext';
import NotFoundPage from './NotFoundPage/NotFoundPage';
import SondaRoute from '../Routes';

const TestPage = lazy(() => import('../pages/TestPage'));
const ResultsPage = lazy(() => import('../pages/Results/Results'));
const SettingsPage = lazy(() => import('../pages/Settings'));
const SupportPage = lazy(() => import('../pages/Support'));
const AboutPage = lazy(() => import('../pages/About'));

const getBasename = () => {
    const baseUrl = import.meta.env.VITE_BASE_URL || "";
    return `/${baseUrl.split('/').pop()}`;
};

const Router = () => {
    const backendStatus = useBackendStatusContext();
    const showBasicMenu = (!backendStatus.state.isAvailable || !backendStatus.state.isCertificateValid) && !backendStatus.state.isUpdating;
    const { isAuthenticated } = useContext(AuthContext);

    return (
        <BrowserRouter basename={getBasename()}>
            <MainLayout>
                <Suspense fallback={<PageSpinner />}>
                    <Routes>
                        <Route path="/" element={<SondaRoute component={TestPage} isAuthenticated={isAuthenticated} showBasicMenu={showBasicMenu} />} />
                        <Route path="/results" element={<SondaRoute component={ResultsPage} isAuthenticated={isAuthenticated} showBasicMenu={showBasicMenu} />} />
                        <Route path="/settings" element={<SondaRoute component={SettingsPage} isAuthenticated={isAuthenticated} showBasicMenu={showBasicMenu} />} />
                        <Route path="/support" element={<SondaRoute component={SupportPage} isAuthenticated={isAuthenticated} showBasicMenu={showBasicMenu} />} />
                        <Route path="/about" element={<SondaRoute component={AboutPage} isAuthenticated={isAuthenticated} showBasicMenu={showBasicMenu} />} />
                        {/* This route will match when no other routes match */}
                        <Route path="*" element={<SondaRoute component={NotFoundPage} isAuthenticated={isAuthenticated} showBasicMenu={showBasicMenu} />} />
                    </Routes>
                </Suspense>
            </MainLayout>
        </BrowserRouter>
    );
};

export default Router;

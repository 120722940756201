import React, { useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { SnackbarContext } from '../../../context/SnackbarContext';
import { useTheme } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BasicSnackbar = () => {
  const { snackbarState, closeSnackbar } = useContext(SnackbarContext);
  const theme = useTheme();


  const getSeverityColor = (severity) => {
    switch (severity) {
      case 'error':
        return { backgroundColor: theme.palette.error.main, color: theme.palette.common.white };
      case 'warning':
        return { backgroundColor: theme.palette.warning.main, color: theme.palette.common.white };
      case 'info':
        return { backgroundColor: theme.palette.info.main, color: theme.palette.common.white };
      case 'success':
        return { backgroundColor: theme.palette.secondary.main, color: theme.palette.common.white };
    }
  };

  return (
    <Snackbar
      open={snackbarState.open}
      autoHideDuration={6000}
      onClose={closeSnackbar}
    >
      <Alert
        onClose={closeSnackbar}
        severity={snackbarState.severity}
        sx={getSeverityColor(snackbarState.severity)}
      >
        {snackbarState.message}
      </Alert>
    </Snackbar>
  );
}; 

export default BasicSnackbar;

import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";
import { StyledCloseIcon } from "../../constants/styledComponents";
import { useTranslation } from "../../context/TranslationContext";
import { CircularProgress } from "@mui/material";


const ConfirmationDialog = ({
    title,
    subtitle,
    isOpen,
    onClose,
    onConfirm,
    inProgress = false,
}) => {
    const {translate} = useTranslation()

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEnforceFocus
            PaperProps={{
                sx: {
                    overflow: "visible",
                },
            }}
        >
            <DialogTitle fontSize={48} id="alert-dialog-title">
                <Box display="flex" justifyContent="center" alignItems="center" width="auto" height={70}>
                    <Box
                        m="auto"
                        style={{
                            whiteSpace: "normal",
                            display: "inline-block",
                        }}
                    >
                        {!inProgress && (
                            <StyledCloseIcon
                                onClick={onClose}
                            />
                        )}
                        {inProgress
                            ?
                            <CircularProgress
                                color="primary" fontSize="large" />
                            :
                            <HelpOutlineIcon
                                color="warning"
                                style={{
                                    top: -80,
                                    fontSize: 120,
                                    overflow: "visible",
                                    position: "relative",
                                    whiteSpace: "nowrap",
                                    display: "inline-block",
                                }}
                            />}   
                    </Box>
                </Box>
                <Typography variant="h5" align="center" style={{ display: "block" }}>
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography variant="body1" align="center">
                        {subtitle}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {!inProgress && (
                    <Button
                        id="dialogCancel"
                        variant="outlined"
                        onClick={onClose}
                        startIcon={<CancelIcon />}
                    >
                        {translate("cancel")}
                    </Button>
                )}
                {!inProgress && (
                    <Button
                        id="dialogAbort"
                        variant="contained"
                        onClick={(e) => onConfirm(e)}
                        startIcon={<CheckCircleIcon />}
                        autoFocus
                    >
                        {translate("confirm")}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;



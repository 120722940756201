import axios from 'axios';

export const authenticateServer = async (jwt) => {
    try {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
        };

        const response = await axios.post(
            `${import.meta.env.VITE_APP_SERVER_URI}/api/v1/auth/token`,
            { jwt: jwt },
            {
                withCredentials: true,
                headers: headers,
            }
        );

        if (response.status === 200) {
            const loginResponse = await axios.post(
                `${import.meta.env.VITE_APP_SERVER_URI}/api/v1/auth/login`,
                { jwt: jwt },
                {
                    withCredentials: true,
                    headers: headers,
                }
            );

            if (loginResponse.status === 200) {
                const csrfToken = loginResponse.data.csrfToken;

                localStorage.setItem("csrfToken", csrfToken);
                const firstName = response.data.user.firstName || "";
                const lastName = response.data.user.lastName || "";

                localStorage.setItem("groups", JSON.stringify(response.data.groups));
                localStorage.setItem("firstName", JSON.stringify(firstName));
                localStorage.setItem("lastName", JSON.stringify(lastName));

                return {
                    firstName,
                    lastName,
                    groups: response.data.groups,
                };
            } else {
                throw new Error("Login failed with status: " + loginResponse.status);
            }
        } else {
            throw new Error("JWT token validation failed with status: " + response.status);
        }
    } catch (error) {
        console.error("Server authentication error:", error);
        throw error;
    }
};

export const formatName = (firstName, lastName) => {
    // Default to empty strings if parameters are null or undefined
    firstName = firstName ?? "";
    lastName = lastName ?? "";

    // Get the first letter of the first name, capitalize and add a dot
    const initial = firstName ? `${firstName.charAt(0).toUpperCase()}.` : "";

    // Capitalize the first letter of the last name and make the rest lowercase
    const formattedLastName = lastName ? lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase() : "";

    // Combine initial and formatted last name, then trim whitespace
    return `${initial} ${formattedLastName}`.trim();
};
import { subYears, endOfDay } from "date-fns";

// Add a leading zero if the value is less than 10
function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
}

// calculating the maximum allowed date
function getMaxDate() {
    return endOfDay(subYears(new Date(), 18)); 
}; 

//calculating the minimum allowed date 
function getMinDate() {
    return endOfDay(subYears(new Date(), 120)); 
}; 

// Format date of birth
function formatDOB(date) {
    if (!(date instanceof Date)) {
        throw new Error("Invalid date object");
    }
    const day = padTo2Digits(date.getDate());
    const month = padTo2Digits(date.getMonth() + 1);
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
}
//checks if the connected user is an admin and he belongs to reyedar group
//the email is the sended email to check if the user we want to modify is the same admin or not
function isAdminInReyedarGroupAndUser(groups, currentUserEmail, userEmail) {
    return isSameUser(currentUserEmail, userEmail) && isInReyedarGroup(groups);
}
//checks if the connected user is the same user we want to modify
function isSameUser(currentUserEmail, userEmail) {
    return currentUserEmail === userEmail;
}
// checks if the connected user belongs to  Reyedar group
function isInReyedarGroup(groups) {
    return groups.includes("Reyedar");
}

export { formatDOB, isAdminInReyedarGroupAndUser, isSameUser, isInReyedarGroup, getMaxDate, getMinDate };

import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material';
import useStyles from '../../constants/commonStyles';
import { Button } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useTranslation } from '../../context/TranslationContext';



const UserCard = ({
  title,
  subtitle,
  text,
  children,
  className,
  onSignOut,
  ...restProps
}) => {
  
  const theme = useTheme();
  const classes = useStyles(theme);
  const {translate} = useTranslation();

  return (
    <Card className={classes.userCard} {...restProps}>
      <CardContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Avatar className={classes.userCardAvatar} />
        <Typography marginTop="5px" className={classes.cardTitle}>
          {title}
        </Typography>
        <Typography className={classes.cardSubtitle}>{subtitle}</Typography>
        <Button style={{ marginTop: 5, fontSize: 12 }} variant="contained" color="primary" endIcon={<ExitToAppIcon />} onClick={onSignOut}>
          {translate('header.SignOut')}
        </Button>
        <Typography variant="body2">
          <small>{text}</small>
        </Typography>
      </CardContent>
      {children}
    </Card>
  );
};

UserCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};


export default UserCard;
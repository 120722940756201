import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@mui/material';

const PasswordCondition = ({ conditionMet, label, isFocused }) => {
    const theme = useTheme();
    const [initialState, setInitialState] = useState(true);

    useEffect(() => {
        if (isFocused) {
            setInitialState(false);
        }
    }, [isFocused]);

    const getColor = () => {
        if (initialState) return theme.palette.text.info;
        if (conditionMet) return theme.palette.secondary.main;
        return isFocused ? theme.palette.text.info : theme.palette.error.main;
    };
    
    return (
        <Typography
            style={{ color: getColor(), display: 'flex', alignItems: 'center' }}
        >
            {conditionMet ? <CheckIcon /> : <CloseIcon />}
            {label}
        </Typography>
    );
};

export default PasswordCondition;

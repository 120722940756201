// src/components/AuthForm.jsx
import React, { useState, useContext, useEffect, useTransition } from "react";
import {
	Box,
	TextField,
	FormControl,
	FormLabel,
	FormGroup,
	Typography,
	Link,
	Button,
	Divider,
	IconButton,
	useTheme,
} from "@mui/material";
import sondaLogo from "../images/sonda-screener.svg";
import PropTypes from "prop-types";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import useCognito from "../hooks/useCognito";
import AuthContext from "../context/authContext";
import { useSnackbar } from "../context/SnackbarContext";
import useStyles from "../constants/commonStyles";
import { authenticateServer } from "../utils/authUtils";
import PasswordCondition from "./common/BasicSnackbar/PasswordCondition";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "../context/TranslationContext";
const passwordValidationRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{12,}$/;
const AuthForm = ({
	showLogo = true,
	usernameLabel = "Email",
	passwordLabel = "authForm.fieldLabels.passwordLabel",
	newPasswordLabel = "authForm.fieldLabels.newPassword",
	confirmPasswordLabel = "authForm.fieldLabels.confirmPassword",
	verificationCodeLabel = "authForm.fieldLabels.verificationCode",
	onLogoClick = () => { },
	buttonText,
	children,
}) => {
	const { translate } = useTranslation();
	
	const usernameInputProps = { type: "email", placeholder: "your@email.com" };
	const passwordInputProps = { type: "password", placeholder: translate(passwordLabel) };
	const newPasswordInputProps = { type: "password", placeholder: translate(newPasswordLabel) };
	const confirmPasswordInputProps = { type: "password", placeholder: translate(confirmPasswordLabel) };
	const verificationCodeInputProps = { type: "password", placeholder: translate(verificationCodeLabel) };
	
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [passwordConfirm, setPasswordConfirm] = useState("");
	const [verificationCode, setVerificationCode] = useState("");
	const [usernameError, setUsernameError] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [newPasswordError, setNewPasswordError] = useState("");
	const [passwordConfirmError, setPasswordConfirmError] = useState("");
	const [verificationCodeError, setVerificationCodeError] = useState("");
	const [userDetails, setUserDetails] = useState(null);
	const [state, setState] = useState(STATE_LOGIN);
	const [errorMessage, setErrorMessage] = useState("");
	const { openSnackbar } = useSnackbar();
	const { setUserData, setIsAuthenticated } = useContext(AuthContext);
	const { userPool, signOut } = useCognito();
	const theme = useTheme();
	const classes = useStyles(theme);
	const [isPasswordFocused, setPasswordFocused] = useState(false);

	const checkConditions = () => ({
		length: password.length >= 12,
		hasNumber: /\d/.test(password),
		hasSpecialChar: /[@$!%*?&.]/.test(password),
		hasUppercase: /[A-Z]/.test(password),
		hasLowercase: /[a-z]/.test(password),
	});
	const conditions = checkConditions();

	const flushPasswords = () => {
		setPasswordConfirm("");
		setPassword("");
	};

	const validateEmail = (email) => {
		if (!email) return translate("authForm.emailRequired");
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailRegex.test(email)) return translate("authForm.invalidEmail");
		return "";
	};

	const validatePassword = (password) => {
		if (!password) return translate("authForm.passwordRequired");
		if (!passwordValidationRegex.test(password) && state !== STATE_LOGIN) {
			return "Invalid password";
		}
		return "";
	};

	useEffect(() => {
		if (errorMessage) {
			setErrorMessage("");
			openSnackbar("error", errorMessage);
		}
	}, [errorMessage, openSnackbar]);

	const usernameErr = validateEmail(username);
	const passwordErr = validatePassword(password);

	const handleSubmit = (event) => {
		event.preventDefault();
		setErrorMessage("");

		setUsernameError(usernameErr);
		setPasswordError(passwordErr);
		if (state === STATE_FORGOT_PASSWORD && usernameErr) {
			return;
		}

		if (state === STATE_FORGOT_PASSWORD_CHANGE) {
			setNewPasswordError(passwordErr);
		}

		const authenticationData = { Username: username, Password: password };
		const authDetails = new AuthenticationDetails(authenticationData);
		const userData = { Username: username, Pool: userPool };
		const cognitoUser = new CognitoUser(userData);

		switch (state) {
			case STATE_LOGIN:
				cognitoUser.authenticateUser(authDetails, {
					onSuccess(result) {
						if (result) {
							authenticateServer(result.getIdToken().getJwtToken())
								.then((data) => {
									flushPasswords();
									setIsAuthenticated(true);
									localStorage.setItem("userEmail", username);
									setUserData((prevData) => ({
										...prevData,
										email: username,
										...data,
									}));
								})
								.catch((error) => {
									if (error.response && error.response.data && error.response.data.message) {
										const statusCode = error.response.status;
										const serverError = error.response.data.message;
										let errorMessage = translate("authForm.serverError");

										switch (statusCode) {
											case 400:
												errorMessage = translate("authForm.badRequest");
												break;
											case 401:
												errorMessage = translate("authForm.unauthorized");
												break;
											case 404:
												errorMessage = `${serverError} ${translate("authForm.notFound")}`;
												break;
											default:
												errorMessage = `${translate("authForm.errorPrefix")} ${statusCode}: ${translate("authForm.generalError")}`;
										}
										setErrorMessage(errorMessage);
									} else if (error.request) {
										console.error("No Response:", error.request);
										setErrorMessage(translate("authForm.noResponse"));
									} else {
										console.error("Request Error:", error.message);
										setErrorMessage(`${translate("authForm.requestError")} ${error.message}`);
									}

									signOut();
								});
						}
					},
					onFailure(err) {
						console.log(err);
						if (err.code === "UserNotFoundException" || err.code === "NotAuthorizedException") {
							setErrorMessage(translate("authForm.invalidCredentials"));
						} else if (err.code === "PasswordResetRequiredException") {
							setErrorMessage(translate("authForm.passwordResetRequired"));
						}
					},
					newPasswordRequired(userAttributes) {
						delete userAttributes.email_verified;
						delete userAttributes.email;
						setUserDetails({ user: cognitoUser, userAttr: userAttributes });
						flushPasswords();
						setState(STATE_PASSWORD_CHANGE);
					},
				});
				break;
			case STATE_FORGOT_PASSWORD:
				cognitoUser.forgotPassword({
					onSuccess(result) {
						flushPasswords();
						setState(STATE_FORGOT_PASSWORD_CHANGE);
					},
					onFailure(err) {
						console.log(err);
						if (err.code === "LimitExceededException") {
							setUsernameError(translate("authForm.attemptLimitExceeded"));
						} else {
							setUsernameError(translate("authForm.failedToInitiatePasswordReset"));
						}
					},
				});
				break;
			case STATE_FORGOT_PASSWORD_CHANGE:
				if (!verificationCode) {
					setVerificationCodeError("Verification code is required.");
					return
				}
				setVerificationCodeError("");
				if (password === passwordConfirm) {
					if (passwordErr) {
						return;
					}
					cognitoUser.confirmPassword(verificationCode, password, {
						onSuccess() {
							flushPasswords();
							setState(STATE_LOGIN);
							setVerificationCodeError("");

							openSnackbar("success", translate("authForm.passwordChangedSuccessfully"));
						},
						onFailure(err) {
							console.log(err);
							if (err.code === "CodeMismatchException") {
								setVerificationCodeError(translate("authForm.invalidVerificationCode"));
							} else if (err.code === "LimitExceededException") {
								setVerificationCodeError(translate("authForm.tooManyAttempts"));
							} else {
								setVerificationCodeError(translate("authForm.failedToVerifyCode"));
							}
						},
					});
				} else {
					setPasswordConfirmError(translate("authForm.confirmPasswordError"));
				}
				break;
			case STATE_PASSWORD_CHANGE:
				if (password === passwordConfirm) {
					if (passwordErr) {
						return;
					}
					userDetails.user.completeNewPasswordChallenge(password, userDetails.userAttr, {
						onSuccess(result) {
							if (result) {
								flushPasswords();
								setState(STATE_LOGIN);
							}
						},
						onFailure(err) {
							console.log(err);
						},
					});
				} else {
					setPasswordConfirmError(translate("authForm.confirmPasswordError"));
				}
				break;
			default:
				break;
		}
	};

	const renderButtonText = () => {
		if (buttonText) return buttonText;

		switch (state) {
			case STATE_PASSWORD_CHANGE:
			case STATE_FORGOT_PASSWORD_CHANGE:
				return translate("authForm.resetPasswordButton");
			case STATE_FORGOT_PASSWORD:
				return translate("authForm.resetPasswordButton");
			case STATE_LOGIN:
			default:
				return "Login";
		}
	};

	const renderForm = () => {
		switch (state) {
			case STATE_PASSWORD_CHANGE:
				return (
					<div className={classes.authContainer}>
						<Typography variant="h6">{translate("authForm.selectNewPassword")}</Typography>
						<FormGroup className={classes.formGroup}>
							<FormControl fullWidth className={classes.formControl}>
								<TextField
									label={translate(newPasswordLabel)}
									id={newPasswordLabel}
									{...newPasswordInputProps}
									value={password}
									onChange={(event) => {
										setPassword(event.target.value);
										setPasswordError("");
									}}
									onFocus={() => setPasswordFocused(true)}
									onBlur={() => {
										setPasswordFocused(false);
									}}
									error={!!passwordError}
									helperText={passwordError}
								/>
								<Box mt={2}>
									<Box mt={2}>
										<PasswordCondition conditionMet={conditions.length} label={translate("authForm.passwordConditions.minCharacters")} isFocused={isPasswordFocused}  />
										<PasswordCondition conditionMet={conditions.hasNumber} label={translate("authForm.passwordConditions.containsNumber")} isFocused={isPasswordFocused}  />
										<PasswordCondition conditionMet={conditions.hasSpecialChar} label={translate("authForm.passwordConditions.containsSpecialChar")} isFocused={isPasswordFocused}  />
										<PasswordCondition conditionMet={conditions.hasUppercase} label={translate("authForm.passwordConditions.containsUppercase")} isFocused={isPasswordFocused} />
										<PasswordCondition conditionMet={conditions.hasLowercase} label={translate("authForm.passwordConditions.containsLowercase")} isFocused={isPasswordFocused}  />
									</Box>
								</Box>
							</FormControl>
						</FormGroup>
						<FormGroup className={classes.formGroup}>
							<FormControl fullWidth className={classes.formControl}>
								<TextField
									label={translate(confirmPasswordLabel)}
									id={confirmPasswordLabel}
									{...confirmPasswordInputProps}
									value={passwordConfirm}
									onChange={(event) => {
										setPasswordConfirm(event.target.value);
										setPasswordConfirmError("");
									}}
									error={!!passwordConfirmError}
									helperText={passwordConfirmError}
								/>
							</FormControl>
						</FormGroup>
					</div>
				);
			case STATE_FORGOT_PASSWORD_CHANGE:
				return (
					<>
						<Typography style={{ marginBottom: "10px" }} variant="h6">
							{translate("authForm.selectNewPassword")}
						</Typography>
						<Typography
							style={{ marginBottom: "10px" }}
							variant="subtitle1"
							color="textSecondary"
							gutterBottom
						>
							{translate("authForm.verificationCodeSent")}
						</Typography>
						<FormGroup fullWidth className={classes.formGroup}>
							<TextField
								label={translate("authForm.fieldLabels.verificationCode")}
								id={verificationCodeLabel}
								{...verificationCodeInputProps}
								value={verificationCode}
								onChange={(event) => {
									setVerificationCode(event.target.value);
									setVerificationCodeError("");
								}}
								error={!!verificationCodeError}
								helperText={verificationCodeError}
							/>
						</FormGroup>
						<FormGroup className={classes.formGroup}>
							<FormControl fullWidth className={classes.formControl}>
								<TextField
									label={translate("authForm.fieldLabels.newPassword")}
									id={newPasswordLabel}
									{...newPasswordInputProps}
									value={password}
									onChange={(event) => {
										setPassword(event.target.value);
										setNewPasswordError("");
									}}
									onFocus={() => setPasswordFocused(true)}
									onBlur={() => {
										setPasswordFocused(false);
									}}
									error={!!newPasswordError}
									helperText={newPasswordError}
								/>
								<Box mt={2}>
									<PasswordCondition conditionMet={conditions.length} label={translate("authForm.passwordConditions.minCharacters")} isFocused={isPasswordFocused}  />
									<PasswordCondition conditionMet={conditions.hasNumber} label={translate("authForm.passwordConditions.containsNumber")} isFocused={isPasswordFocused}  />
									<PasswordCondition conditionMet={conditions.hasSpecialChar} label={translate("authForm.passwordConditions.containsSpecialChar")} isFocused={isPasswordFocused}  />
									<PasswordCondition conditionMet={conditions.hasUppercase} label={translate("authForm.passwordConditions.containsUppercase")} isFocused={isPasswordFocused}  />
									<PasswordCondition conditionMet={conditions.hasLowercase} label={translate("authForm.passwordConditions.containsLowercase")} isFocused={isPasswordFocused}  />
								</Box>
							</FormControl>
						</FormGroup>
						<FormGroup className={classes.formGroup}>
							<FormControl fullWidth className={classes.formControl}>
								<TextField
									label={translate("authForm.fieldLabels.confirmPassword")}
									id={confirmPasswordLabel}
									{...confirmPasswordInputProps}
									value={passwordConfirm}
									onChange={(event) => {
										setPasswordConfirm(event.target.value);
										setPasswordConfirmError("");
									}}
									error={!!passwordConfirmError}
									helperText={passwordConfirmError}
								/>
							</FormControl>
						</FormGroup>
					</>
				);
			case STATE_FORGOT_PASSWORD:
				return (
					<>
						<Typography style={{ marginBottom: "10px" }} variant="subtitle1">
							{translate("authForm.forgotPasswordMessage")}
						</Typography>
						<Box component="form" noValidate>
							<FormGroup className={classes.formGroup}>
								<TextField
									label={usernameLabel}
									{...usernameInputProps}
									value={username}
									onChange={(event) => {
										setUsername(event.target.value);
										setUsernameError("");
									}}
									id={usernameLabel}
									error={!!usernameError}
									helperText={usernameError}
								/>
							</FormGroup>
						</Box>
					</>
				);
			case STATE_LOGIN:
			default:
				return (
					<>
						<Typography color="textPrimary" className={classes.centeredText}>
							{translate("authForm.signInMessage")}
						</Typography>
						<FormGroup className={classes.formGroup}>
							<TextField
								label={usernameLabel}
								id={usernameLabel}
								{...usernameInputProps}
								value={username}
								onChange={(event) => {
									setUsername(event.target.value);
									setUsernameError("");
								}}
								error={!!usernameError}
								helperText={usernameError}
							/>
						</FormGroup>
						<FormGroup className={classes.formGroup}>
							<TextField
								label={translate(passwordLabel)}
								id={passwordLabel}
								{...passwordInputProps}
								value={password}
								onChange={(event) => {
									setPassword(event.target.value);
									setPasswordError("");
								}}
								type="password"
								error={!!passwordError}
								helperText={passwordError}
							/>
						</FormGroup>
						<div className={classes.linkContainer}>
							<Link
								href="#"
								color="primary"
								underline="hover"
								className={classes.rightAlignedText}
								onClick={() => {
									setState(STATE_FORGOT_PASSWORD);
								}}
							>
								{translate("authForm.forgotPassword")}
							</Link>
						</div>
					</>
				);
		}
	};

	return (
		<div className={classes.authContainer}>
			<Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
				{/* <LanguageSelector /> */}
			</Box>
			<FormControl component="form" onSubmit={handleSubmit} className={classes.formControl}>
				{showLogo && (
					<Box display="flex" justifyContent="center" pb={4}>
						<IconButton
							onClick={onLogoClick}
							disableRipple
							sx={{ "&:hover, &:focus": { cursor: "default" } }}
						>
							<img src={sondaLogo} className={classes.logo} alt="logo" />
						</IconButton>
					</Box>
				)}
				<Box mb={2}>{renderForm()}</Box>
				{errorMessage && (
					<>
						<Divider />
						<FormLabel className={classes.authError}>{errorMessage}</FormLabel>
					</>
				)}
				<Divider />
				<TextField type="submit" style={{ display: "none" }} />
				<Button
					variant="contained"
					size="large"
					className={classes.submitButton}
					fullWidth
					onClick={handleSubmit}
					color="primary"
					sx={{ backgroundColor: theme.palette.primary.main }}
				>
					{renderButtonText()}
				</Button>
				<Box className={classes.formGroup}>{children}</Box>
			</FormControl>
		</div>
	);
};

export const STATE_LOGIN = "LOGIN";
export const STATE_FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const STATE_FORGOT_PASSWORD_CHANGE = "FORGOT_PASSWORD_CHANGE";
export const STATE_PASSWORD_CHANGE = "PASSWORD_CHANGE";

AuthForm.propTypes = {
	showLogo: PropTypes.bool,
	usernameLabel: PropTypes.string,
	usernameInputProps: PropTypes.object,
	passwordLabel: PropTypes.string,
	passwordInputProps: PropTypes.object,
	newPasswordLabel: PropTypes.string,
	newPasswordInputProps: PropTypes.object,
	confirmPasswordLabel: PropTypes.string,
	confirmPasswordInputProps: PropTypes.object,
	verificationCodeLabel: PropTypes.string,
	verificationCodeInputProps: PropTypes.object,
	onLogoClick: PropTypes.func,
	buttonText: PropTypes.string,
	children: PropTypes.node,
};

export default AuthForm;

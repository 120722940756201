import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import useStyles from '../../constants/commonStyles';
import appInfo from '../../../package.json'

const Footer = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  // using useMemo to memoize the current year
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <div className={classes.footer}>
      <Typography variant="body1" color="textSecondary">
        © {currentYear} {appInfo.companyName}
      </Typography>
    </div>
  );
};

export default Footer;

import React, { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";

const SondaRoute = ({ component: Component, isAuthenticated, showBasicMenu, ...rest }) => {
	if (!isAuthenticated) {
		return <Navigate to="/" />;
	}

	if (showBasicMenu) {
		return <Navigate to="/support" />;
	}

	return (
		<Suspense fallback={<div>Loading...</div>}>
			<Component {...rest} />
		</Suspense>
	);
};

export default SondaRoute;

import React, { createContext, useState, useContext } from 'react';
import en from '../locales/en.json';
import nl from '../locales/nl.json';

const translations = { en, nl };

const TranslationContext = createContext();

export const useTranslation = () => useContext(TranslationContext);

export const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem('preferredLanguage') || 'en');

  const translate = (key, params = {}) => {
    if (!key || typeof key !== 'string') {
      console.warn(`Translation key is invalid: ${key}`);
      return key;
    }

    const keys = key.split('.');
    let translation = keys.reduce((acc, k) => acc && acc[k], translations[language]) || key;

    Object.keys(params).forEach(param => {
      const regex = new RegExp(`{${param}}`, 'g');
      translation = translation.replace(regex, params[param]);
    });

    return translation;
  };

  const changeLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem('preferredLanguage', lang);
  };

  return (
    <TranslationContext.Provider value={{ translate, changeLanguage, language }}>
      {children}
    </TranslationContext.Provider>
  );
};

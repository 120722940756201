// Helper function to compare version numbers
export function compareVersions(v1, v2) {
	const v1Parts = v1.split(".").map(Number);
	const v2Parts = v2.split(".").map(Number);

	for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
		const v1Part = v1Parts[i] || 0;
		const v2Part = v2Parts[i] || 0;

		if (v1Part > v2Part) return 1;
		if (v1Part < v2Part) return -1;
	}
	return 0;
}

// Helper function to find the closest next version
export function findClosestNextVersion(versions, currentVersion) {
	const sortedVersions = versions.sort(compareVersions);
	return sortedVersions.find(version => compareVersions(version, currentVersion) > 0);
}

// Helper function to check backend compatibility with frontend
export function isBackendCompatible(frontendVersionInfo, currentBackendVersion) {
	return frontendVersionInfo && frontendVersionInfo.compatibleBackends.includes(currentBackendVersion);
}

// Helper function to filter backend versions with current version as a compatible previous version
export function filterCompatiblePreviousVersions(backendVersions, currentBackendVersion, isPreReleaseTester) {
	return backendVersions.filter(backendVersion => {
		const compatiblePrevious = backendVersion.compatiblePreviousVersions;
		const isReleasedCondition = isPreReleaseTester || backendVersion.isReleased;
		return compatiblePrevious && Object.keys(compatiblePrevious).includes(currentBackendVersion) && isReleasedCondition;
	});
}

// Helper function to process compatible versions data
export function processCompatibleVersionsData(payloadData, currentBackendVersion, currentFrontendVersion, savedCompatibility, isPreReleaseTester) {
	let isBackendCompatibleWithFrontend = JSON.parse(savedCompatibility);

	// Find the current frontend version in the compatibility matrix
	const frontendVersionInfo = payloadData.frontendVersions.find(
		(fVersion) => fVersion.version === currentFrontendVersion
	);

	// Check if the current backend version is in the compatibleBackends
	isBackendCompatibleWithFrontend = isBackendCompatible(frontendVersionInfo, currentBackendVersion);

	// Filter to find backend versions with currentBackendVersion as a compatible previous version
	const backendVersionsWithCurrentAsCompatiblePrevious = filterCompatiblePreviousVersions(
		payloadData.backendVersions,
		currentBackendVersion,
		isPreReleaseTester
	);

	// Find the closest next version using a numeric comparison
	const backendVersionNumbers = backendVersionsWithCurrentAsCompatiblePrevious.map(backendVersion => backendVersion.version);
	const closestNextBackendVersion = findClosestNextVersion(backendVersionNumbers, currentBackendVersion);

	let isUpdateRequired = false;
	let isUpdatePossible = false;

	// Determine if an update is required based on compatibility and/or version advancement
	if (!isBackendCompatibleWithFrontend && closestNextBackendVersion && currentBackendVersion !== closestNextBackendVersion) {
		isUpdateRequired = true;
	}

	// Update isUpdatePossible based on closestNextBackendVersion
	if (closestNextBackendVersion != null) {
		isUpdatePossible = true;
	}

	return {
		isBackendCompatibleWithFrontend,
		closestNextBackendVersion,
		isUpdateRequired,
		isUpdatePossible,
	};
}

import grey from "@mui/material/colors/grey";

export const themes = {
  light: {
    mode: "light",
    primary: {
      main: "#00b398",
      contrastText: grey[100],
      dark: "#007864",
      light: "#34cbb2",
    },
    secondary: {
      main: "#009D83",
      contrastText: grey[100],
      dark: "#004d40",
      light: "#48a999",
    },
    background: {
      default: "#f8f9fa",
      paper: "#f5f5f5",
      common: "#fff",
    },
    text: {
      primary: "#000",
      info: "#757575",
    },
    error: {
      main: "#D32F2F",
    },
    warning: {
      main: "#ff9800",
    },
    neutral: {
      main: "#8695a7",
    },
    common: {
      white: "#fff",
      lightGrey: "#F2F2F2",
      greyBorder: "#D9D9D9",
      userCard: "#F5F5F5"
    },
  },
  dark: {
    mode: "dark",
    primary: {
      main: "#00b398",
      contrastText: grey[100],
      dark: "#007864",
      light: "#34cbb2",
    },
    secondary: {
      main: "#008F76",
      contrastText: grey[100],
      dark: "#007864",
      light: "#48a999",
    },
    background: {
      default: "#1E2A30",
      paper: "#1F2730",
      common: "#24333D",
    },
    text: {
      primary: "#fff",
      info: "#B0BEC5",
    },
    error: {
      main: "#EF5350",
    },
    warning: {
      main: "#E29E21",
    },
    neutral: {
      main: "#97A4AF",
    },
    common: {
      white: "#fff",
      lightGrey: "#394147",
      greyBorder: "#7C7C7C",
      userCard: "#2F4858"
    },
  },
};
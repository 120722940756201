export const ACQUIRE_DATA_BEGIN = 'ACQUIRE_DATA_BEGIN';
export const ACQUIRE_DATA_SUCCESS = 'ACQUIRE_DATA_SUCCESS';
export const ACQUIRE_DATA_ERROR = 'ACQUIRE_DATA_ERROR';

export const SETUP_USER_BEGIN = 'SETUP_USER_BEGIN';
export const SETUP_USER_SUCCESS = 'SETUP_USER_SUCCESS';
export const SETUP_USER_ERROR = 'SETUP_USER_ERROR';

export const CLEAR_ALERT = 'CLEAR_ALERT';
export const DISPLAY_ALERT = 'SHOW_ALERT';

export const HANDLE_CHANGE = 'HANDLE_CHANGE';
export const CLEAR_VALUES = 'CLEAR_VALUES';

export const HANDLE_TOGGLE_COMPATIBILITY = 'HANDLE_TOGGLE_COMPATIBILITY';

export const GET_TESTS_BEGIN = 'GET_TESTS_BEGIN';
export const GET_TESTS_SUCCESS = 'GET_TESTS_SUCCESS';
export const GET_TEST_TYPES_BEGIN = 'GET_TEST_TYPES_BEGIN';
export const GET_TEST_TYPES_SUCCESS = 'GET_TEST_TYPES_SUCCESS';

export const SAVE_TEST_BEGIN = 'SAVE_TEST_BEGIN';
export const SAVE_TEST_SUCCESS = 'SAVE_TEST_SUCCESS';
export const SAVE_TEST_ERROR = 'SAVE_TEST_ERROR';

export const SHOW_REPORT_BEGIN = 'SHOW_REPORT_BEGIN';
export const SHOW_REPORT_SUCCESS = 'SHOW_REPORT_SUCCESS';

export const SET_ACQUIRE_RUNNING = 'SET_ACQUIRE_RUNNING';
export const SET_MOCKED_TEST = 'SET_MOCKED_TEST';
export const SET_BYPASS_PHONE = 'SET_BYPASS_PHONE';
import React from 'react';
import { MenuItem, Select, useTheme } from '@mui/material';
import { useTranslation } from '../context/TranslationContext';
import UKFlag from '../assets/img/countries/united_kingdom_flag.svg'; 
import NetherlandsFlag from '../assets/img/countries/dutch_flag.svg';

const LanguageSelector = () => {
  const { changeLanguage, language } = useTranslation();
	const theme = useTheme();
  return (
    <Select
      value={language}
      onChange={(e) => changeLanguage(e.target.value)}
      displayEmpty
      style={{
        color: theme.palette.text.info,
        marginRight: '20px',
        fontSize: '12px',
        height: '40px',
        minWidth: '60px'
      }} // Adjust styles as needed
      inputProps={{
        style: {
          padding: '6px 10px'
        }
      }}
    >
      <MenuItem value="en" style={{ fontSize: '12px', display: 'flex', alignItems: 'center', }}>
      <img
          src={UKFlag}
          width="16"
          height="12"
          alt="United Kingdom"
          style={{ marginRight: '4px' }}
        />
        EN
      </MenuItem>
      <MenuItem value="nl" style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
      <img
          src={NetherlandsFlag}
          width="16"
          height="12"
          alt="Netherlands"
          style={{ marginRight: '4px' }}
        />
        NL
      </MenuItem>
    </Select>
  );
};

export default LanguageSelector;

import {
	ACQUIRE_DATA_BEGIN,
	ACQUIRE_DATA_SUCCESS,
	ACQUIRE_DATA_ERROR,
	DISPLAY_ALERT,
	CLEAR_ALERT,
	CLEAR_VALUES,
	GET_TESTS_BEGIN,
	GET_TESTS_SUCCESS,
	GET_TEST_TYPES_BEGIN,
	GET_TEST_TYPES_SUCCESS,
	SAVE_TEST_BEGIN,
	SAVE_TEST_SUCCESS,
	SAVE_TEST_ERROR,
	SHOW_REPORT_BEGIN,
	SHOW_REPORT_SUCCESS,
	HANDLE_CHANGE,
	HANDLE_TOGGLE_COMPATIBILITY,
	SET_ACQUIRE_RUNNING,
	SET_MOCKED_TEST,
	SET_BYPASS_PHONE
} from "./actions";

const reducer = (state, action) => {
	if (action.type === DISPLAY_ALERT) {
		return {
			...state,
			showAlert: true,
			alertType: "danger",
			alertText: "Please provide all values!",
		};
	}
	if (action.type === CLEAR_ALERT) {
		return {
			...state,
			showAlert: false,
			alertType: "",
			alertText: "",
		};
	}

	if (action.type === ACQUIRE_DATA_BEGIN) {
		return { ...state, isLoading: true, showAlert: false, isAcquireRunning: true};
	}
	if (action.type === ACQUIRE_DATA_SUCCESS) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			// data: action.payload.data,
			alertType: "success",
			alertText: "Data Acquisition Completed! Redirecting...",
		};
	}
	if (action.type === ACQUIRE_DATA_ERROR) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: "danger",
			alertText: action.payload.msg,
		};
	}

	if (action.type === SAVE_TEST_BEGIN) {
		return { ...state, isLoading: true };
	}

	if (action.type === SAVE_TEST_SUCCESS) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: "success",
			alertText: "Test Saved",
		};
	}
	if (action.type === SAVE_TEST_ERROR) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: "danger",
			alertText: action.payload.msg,
		};
	}
	if (action.type === HANDLE_TOGGLE_COMPATIBILITY) {
		return {
			...state,
			bypassCompatibility: action.payload,
		};
	}

	if (action.type === CLEAR_VALUES) {
		const initialState = {
			isEditing: false,
			recordingId: "",
			testId: "",
			firstName: "",
			lastName: "",
			birthDate: "",
			gender: "",
			testType: "",
			eye: "",
			isAcquireRunning: false,
		};

		return {
			...state,
			...initialState,
		};
	}

	if (action.type === GET_TESTS_BEGIN) {
		return { ...state, isLoading: true, showAlert: false };
	}
	if (action.type === GET_TESTS_SUCCESS) {
		return {
			...state,
			isLoading: false,
			tests: action.payload.tests,
			testsTotal: action.payload.testsTotal
		};
	}

	if (action.type === GET_TEST_TYPES_BEGIN) {
		return { ...state, isLoading: true, showAlert: false };
	}
	if (action.type === GET_TEST_TYPES_SUCCESS) {
		return {
			...state,
			isLoading: false,
			testTypes: action.payload.testTypes,
		};
	}

	if (action.type === SAVE_TEST_BEGIN) {
		return { ...state, isLoading: true };
	}

	if (action.type === SAVE_TEST_SUCCESS) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: "success",
			alertText: "Test Saved",
		};
	}
	if (action.type === SAVE_TEST_ERROR) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: "danger",
			alertText: action.payload.msg,
		};
	}

	if (action.type === SHOW_REPORT_BEGIN) {
		return { ...state, isLoading: true };
	}
	if (action.type === SHOW_REPORT_SUCCESS) {
		return { ...state, isLoading: false };
	}

	if (action.type === HANDLE_CHANGE) {
		return { ...state, isLoading: false };
	}

	if (action.type === SET_ACQUIRE_RUNNING) {
		return { ...state, isAcquireRunning: action.payload };
	}
	if(action.type === SET_MOCKED_TEST){
		return { ...state, isTestMocked: action.payload };
	}
	if(action.type === SET_BYPASS_PHONE){
		return { ...state, isPhoneBypassed: action.payload };
	}

	throw new Error(`no such action : ${action.type}`);
};

export default reducer;

import axios from "../services/httpService/httpService";
import { compatibleVersions } from "../services/compatibilityService/compatibilityService";

import { compatibleVersions as fetchCompatibleVersions } from "../services/compatibilityService/compatibilityService";

let canFetchCompatibleVersions = true;

export const fetchCompatibleVersionsData = async (userPool) => {
	const versionsStatus = {
		data: null,
		error: null,
	};

	if (!canFetchCompatibleVersions) {
		// console.log("Fetching compatible versions is rate limited.");
		versionsStatus.error = "Rate limited";
		return versionsStatus;
	}

	canFetchCompatibleVersions = false;

	try {
		const versionsData = await fetchCompatibleVersions(userPool);
		versionsStatus.data = versionsData;
	} catch (error) {
		console.error("Error fetching compatible versions:", error);
		versionsStatus.error = error;
	}

	setTimeout(() => {
		canFetchCompatibleVersions = true;
	}, 5000);

	return versionsStatus;
};

export const checkBackendStatus = async () => {
	let status = {
		version: "",
		isAvailable: true,
		isCertValid: true,
	};

	const requestConfig = {
		method: "GET",
		url: `${import.meta.env.VITE_APP_SERVER_URI}/${import.meta.env.VITE_APP_DATA_ACQUISITION_API_VERSION}/version`,
	};

	const response = await axios(requestConfig).catch((err) => {
		throw err;
	});

	if (response?.status === 200 && response?.data.version) {
		status = { ...status, version: response.data.version };
	}

	return status;
};

export const getAllAvailableBackendVersions = async (showAll = false) => {
	let status = {
		versions: [], // Changed from version to versions to store multiple versions
	};

	// Retrieve the CSRF token from localStorage
	const csrfToken = localStorage.getItem("csrfToken");

	const headers = {
		"Content-Type": "application/json; charset=utf-8",
		"X-CSRF-Token": csrfToken, // Add the CSRF token to the request headers
	};

	const requestConfig = {
		method: "GET",
		url: `${import.meta.env.VITE_APP_SERVER_URI}/${import.meta.env.VITE_APP_DATA_ACQUISITION_API_VERSION}/available-versions`,
		// params: { all: showAll },  // Assuming the backend accepts a query parameter to decide whether to send all versions or not
		headers: headers, // Add headers to the request config
		withCredentials: true,
	};

	const response = await axios(requestConfig).catch((err) => {
		throw err;
	});

	if (response?.status === 200 && response?.data.versions) {
		// Assuming the backend returns an array named versions
		status = { ...status, versions: response.data.versions };
	}

	return status;
};

export const updateBackend = async (version = null) => {
	let responseStatus = "500";

	// Use the provided version or default to compatibleVersions.updateVersion
	// const targetVersion = version || compatibleVersions.updateVersion;
	const targetVersion = version || compatibleVersions.backendVersions.version;

	if (targetVersion) {
		const requestConfig = {
			method: "PUT",
			url: `${import.meta.env.VITE_APP_SERVER_URI}/${import.meta.env.VITE_APP_DATA_ACQUISITION_API_VERSION}/update`,
			data: { version: targetVersion },
		};
		try {
			const response = await axios(requestConfig);
			responseStatus = response?.status;
		} catch (err) {
			throw err;
		}
	}
	return responseStatus;
};

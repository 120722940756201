import { createContext, useContext, useReducer } from "react";

import reducer from './backendStatusReducer'

const BackendStatusContext = createContext()

export const BackendStatusProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, {
        isCertificateValid: true,
        isAvailable: true,
        isUpdatePossible: false,
        isUpdateRequired: false,
        isUpdateRequested: false,
        isUpdating: false,
        updateMessage: {
            message: '',
            status: ''
        },
        version: '',
        frontendVersion: '',
        savedTargetVersion: '',
    })

    return (
        <BackendStatusContext.Provider value={{state, dispatch}}>
            {children}
        </BackendStatusContext.Provider>
    );
}

export const useBackendStatusContext = () => useContext(BackendStatusContext)
import React, { createContext } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

const ScreenResolutionContext = createContext();

const ScreenResolutionProvider = ({ children }) => {
    const isXs = useMediaQuery('(max-width:600px)');
    const isSm = useMediaQuery('(min-width:600px) and (max-width:959px)');
    const isMd = useMediaQuery('(min-width:960px) and (max-width:1279px)');
    const isLg = useMediaQuery('(min-width:1280px) and (max-width:1919px)');
    const isLandscape = useMediaQuery('(orientation: landscape) and (max-width: 950px)');
    const screenSizeCategory = isXs ? 'xs' : isSm ? 'sm' : isMd ? 'md' : isLg ? 'lg' : 'xl';
    return (
        <ScreenResolutionContext.Provider value={{ screenSizeCategory, isLandscape }}>
            {children}
        </ScreenResolutionContext.Provider>
    );
};

export { ScreenResolutionProvider, ScreenResolutionContext };
import { styled, keyframes, Paper, Box, TableCell, ToggleButtonGroup, Card, IconButton, Button, Grid, Link} from '@mui/material';
import {ListItem, ListItemButton }from "@mui/material";
import { TableContainer } from '@mui/material';
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CloseIcon from "@mui/icons-material/Close";



export const StyledItem = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    backgroundColor: theme.palette.background.common,
})); 

export const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const StyledSupportBox = styled('div')({
  padding: '20px',
});

export const StyledDividerBox = styled(Box)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const SupportStyledItem = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.common.greyBorder}`,
  borderRadius: '20px',
  padding: theme.spacing(3),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.common,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '480px',
  maxWidth: '480px',
  margin: "auto",
  [theme.breakpoints.down('sm')]: {
    width: '320px',
    maxWidth: '320px',
  },
}));

export const StyledCenteredBox = styled("div")(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledOSIcons = styled("div")(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '32px',
  marginBottom: "18px",
  marginTop: "26px",
  color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
    gap: '18px',
  },
}));

export const StyledLabelBox = styled(Box)(({ theme }) => ({
  border: `1px solid black`,
  width: 760,
  height: 640,
  backgroundColor: "white",
  color : "black"
}));

export const StyledVersionsBox = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  minWidth: "200px",
  maxWidth: "760px", 
  marginLeft: "10px",
  marginTop: "10px"
}))

export const StyledTableContainer = styled(TableContainer)(() => ({
    height: "100%",
})); 

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
	fontWeight: "bold",
	color: theme.palette.common.white,
}));

export const UsersTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: 4,
  margin: "10px 10px",
  maxWidth: "960px",
  width: "auto",
  backgroundColor: theme.palette.background.common,
})); 


//urlChecker

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const RotatingIcon = styled(AutorenewIcon)(({theme}) => ({
    animation: `${rotate} 2s linear infinite`,
    color: theme.palette.info.main
}));

export const StyledCardContainer = styled(Card)(({theme}) => ({
  backgroundColor: theme.palette.background.common,
}));

export const StyledIcon = styled('div')(({ hoverColor }) => ({
  fontSize: '35px',
  transition: 'color 0.3s',
  cursor: "pointer",
  '&:hover': {
    color: hoverColor,
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  color: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  width: '50px',
  height: '40px',
  borderRadius: '5px',
  border: `1px solid ${theme.palette.primary.main}`,
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  
})); 

export const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
})); 

export const StyledLink = styled(Link)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

export const StyledListItem = styled(ListItem)(() => ({
 root: {
    "&:hover": {
      color: "white",
    },
  },
})); 

export const StyledListItemButton = styled(ListItemButton)(() => ({
  root: {
     "&:hover": {
       color: "white",
     },
   },
 })); 


export const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
        top: -50,
        right: -330,
        fontSize: 24,
        overflow: "visible",
        position: "relative",
        whiteSpace: "nowrap",
        display: "inline-block",
        cursor: "pointer",
        color: theme.palette.main,
        transition: "color 0.3s ease, transform 0.3s ease",
        "&:hover": {
            color: theme.palette.error.main,
            transform: "scale(1.1)",
        },
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        '& .MuiToggleButtonGroup-grouped': {
          '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
          },
          '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
          },
          "&.Mui-selected, &.Mui-selected:hover": {
            color: "white",
            backgroundColor: theme.palette.primary.main,
          }
        },
    }));


export const getStatusTypographyStyle = (status, theme) => ({
  color:
    status === "down"
      ? theme.palette.error.main
      : status === "starting"
      ? theme.palette.warning.main
      : status === "checking"
      ? theme.palette.info.main
      : theme.palette.primary.main,
});




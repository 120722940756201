import axios from "axios";
import { useEffect, useState, useCallback } from "react";
import { useAppContext } from "../context/appContext";
import { authenticateServer } from "../utils/authUtils";
import useCognito from "../hooks/useCognito";

const useHealthCheck = (url, signOut) => {
	const { setGlobalHealthStatus, isAcquireRunning } = useAppContext();
	const [healthStatus, setHealthStatus] = useState("checking");
	const [progress, setProgress] = useState(0);
	const { getAuthenticatedUser } = useCognito();

	const handleResponse = useCallback((response) => {
		if (response.data.readiness === "starting") {
			const { progress: progressValue } = response.data;
			setHealthStatus("starting");
			setGlobalHealthStatus("starting");
			setProgress(progressValue);
		} else if (response.status === 200) {
			setHealthStatus("up");
			setGlobalHealthStatus("up");
		} else {
			setHealthStatus("down");
			setGlobalHealthStatus("down");
			setProgress(0);
		}

		if (response.status === 401 || response.status === 403) {
			signOut();
		}
	}, [setGlobalHealthStatus, signOut]);

	const refreshAuthentication = useCallback(async () => {
		try {
			const user = await getAuthenticatedUser();
			if (user) {
				const session = await new Promise((resolve, reject) => {
					user.getSession((err, session) => {
						if (err) {
							reject(err);
						} else {
							resolve(session);
						}
					});
				});
				const idToken = session.getIdToken().getJwtToken();
				await authenticateServer(idToken);
			}
		} catch (authError) {
			console.error("Error re-authenticating with server:", authError);
			// TODO: Consider sign out user if re-authentication fails
		}
	}, [getAuthenticatedUser]);

	const handleError = useCallback(async (error) => {
		setHealthStatus("down");
		setProgress(0);
		if (error.response) {
			const status = error.response.status;
			if (status === 401 || status === 403) {
				signOut();
			} else if (status === 500 || status === 502) {
				await refreshAuthentication();
			}
		}
	}, [signOut, refreshAuthentication]);

	const fetchStatus = useCallback(async () => {
		try {
			const response = await axios.get(url);
			handleResponse(response);
		} catch (error) {
			handleError(error);
		}
	}, [url, handleResponse, handleError]);

	useEffect(() => {
		if (!isAcquireRunning) {
			const interval = setInterval(fetchStatus, 1000);
			return () => clearInterval(interval);
		}
	}, [fetchStatus, isAcquireRunning]);

	return { healthStatus, progress };
};

export default useHealthCheck;

// src/hooks/useCognito.js
import { useCallback, useEffect, useContext, useRef } from "react";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import AuthContext from "../context/authContext";
import config from "../cognito.json";
import { authenticateServer } from "../utils/authUtils";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

export default function useCognito() {
	const { setIsAuthenticated, setUserData } = useContext(AuthContext);
	const userPool = new CognitoUserPool({
		UserPoolId: config.aws_user_pools_id,
		ClientId: config.aws_user_pools_web_client_id,
	});
	const isRefreshingRef = useRef(false);
	const refreshPromiseRef = useRef(null);

	const decodeJWT = (token) => {
		const base64Url = token.split(".")[1];
		const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
		const jsonPayload = decodeURIComponent(
			atob(base64)
				.split("")
				.map((c) => {
					return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join("")
		);
		return JSON.parse(jsonPayload);
	};

	const getAuthenticatedUser = useCallback(() => {
		const user = userPool.getCurrentUser();
		if (!user) {
			return Promise.reject(new Error("No authenticated user found"));
		}

		return new Promise((resolve, reject) => {
			user.getSession(async (err, session) => {
				if (err) {
					console.error("Error getting session:", err);
					return reject(err);
				}

				const idToken = session.getIdToken().getJwtToken();
				const decodedToken = decodeJWT(idToken);
				const currentTime = Math.floor(Date.now() / 1000);
				const timeLeft = decodedToken.exp - currentTime;

				if (timeLeft < 60 || decodedToken.exp < currentTime) {
					if (!isRefreshingRef.current) {
						isRefreshingRef.current = true;
						refreshPromiseRef.current = new Promise((refreshResolve, refreshReject) => {
							user.refreshSession(session.getRefreshToken(), async (refreshErr, newSession) => {
								isRefreshingRef.current = false;
								if (refreshErr) {
									console.error("Error refreshing session:", refreshErr);
									refreshReject(refreshErr);
								} else {
									console.log("Session refreshed successfully.");
									const newIdToken = newSession.getIdToken().getJwtToken();
									const serverAuthData = await authenticateServer(newIdToken);
									setUserData((prevData) => ({
										...prevData,
										...serverAuthData,
									}));
									refreshResolve(user);
								}
							});
						});
					}
					return refreshPromiseRef.current.then(resolve).catch(reject);
				} else {
					resolve(user);
				}
			});
		});
	}, [userPool, setUserData]);

	useEffect(() => {
		getAuthenticatedUser()
			.then(() => setIsAuthenticated(true))
			.catch((err) => {
				console.error("Authentication check failed:", err);
				setIsAuthenticated(false);
			});
	}, [getAuthenticatedUser, setIsAuthenticated]);

	const signOut = () => {
		logoutServer();
		localStorage.removeItem("groups");
		localStorage.removeItem("userEmail");
		localStorage.removeItem("firstName");
		localStorage.removeItem("lastName");
		setIsAuthenticated(false);
		return userPool.getCurrentUser()?.signOut();
	};

	const signOutClient = () => {
		setIsAuthenticated(false);
		return userPool.getCurrentUser()?.signOut();
	};

	const logoutServer = async () => {
		const csrfToken = localStorage.getItem("csrfToken");
		const headers = {
			"Content-Type": "application/json; charset=utf-8",
			"X-CSRF-Token": csrfToken,
		};

		try {
			const logoutResponse = await fetch(
				`${import.meta.env.VITE_APP_SERVER_URI}/${
					import.meta.env.VITE_APP_AUTH_SERVICE_API_VERSION
				}/auth/logout`,
				{ method: "POST", credentials: "include", headers }
			);

			if (logoutResponse.status === 400) {
				console.error("Logout failed: Bad request");
			} else if (logoutResponse.status === 500) {
				console.error("Logout failed: Internal server error");
			} else if (logoutResponse.ok) {
				console.log("Logout successful");
			} else {
				const error = await logoutResponse.json();
				console.error("Logout failed:", error);
			}
		} catch (error) {
			console.error("Logout error:", error);
		}
	};

	const getSession = (user) => {
		return new Promise((resolve, reject) => {
			user.getSession((err, session) => {
				if (err) {
					console.error("Error getting session:", err);
					return reject(err);
				}
				resolve(session);
			});
		});
	};

	const refreshSession = (user) => {
		return new Promise((resolve, reject) => {
			user.getSession((err, session) => {
				if (err) {
					console.error("Error refreshing session:", err);
					return reject(err);
				}

				user.refreshSession(session.getRefreshToken(), (refreshErr, newSession) => {
					if (refreshErr) {
						console.error("Error refreshing session:", refreshErr);
						return reject(refreshErr);
					}
					resolve(newSession);
				});
			});
		});
	};

	const getPreSignedUrl = async (url) => {
		const user = await getAuthenticatedUser();
		if (!user) {
			throw new Error("User not authenticated.");
		}

		try {
			const session = await getSession(user);
			if (!session.isValid()) {
				await refreshSession(user);
			}
			const idToken = session.getIdToken().getJwtToken();
			const { hostname, pathname } = new URL(url);
			const bucketName = hostname.split(".")[0];
			const fileName = pathname.substring(1);

			const aws_region = "eu-central-1";
			const identity_pool_id = config.aws_identity_pool_id;

			const s3Client = new S3Client({
				region: aws_region,
				credentials: fromCognitoIdentityPool({
					clientConfig: { region: aws_region },
					identityPoolId: identity_pool_id,
					logins: {
						[`cognito-idp.${aws_region}.amazonaws.com/${config.aws_user_pools_id}`]: idToken,
					},
				}),
			});

			const command = new GetObjectCommand({ Bucket: bucketName, Key: fileName });
			const signedUrl = await getSignedUrl(s3Client, command, { expiresIn: 60 });
			return signedUrl;
		} catch (error) {
			console.error("Error generating signed URL:", error);
			throw new Error("User session is not valid.");
		}
	};

	return {
		userPool,
		getAuthenticatedUser,
		signOut,
		signOutClient,
		getPreSignedUrl,
	};
}

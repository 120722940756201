import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";

// Extract the region from the Cognito User Pool ID
const getRegionFromUserPoolId = (userPoolId) => {
    const region = userPoolId.split("_")[0];
    return region;
};

// Retrieve the current Cognito user session
const getCurrentUserSession = (userPool) => {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
        console.error("No current user found in User Pool.");
        return Promise.reject(new Error("No current user"));
    }
    return new Promise((resolve, reject) => {
        cognitoUser.getSession((err, session) => {
            if (err) {
                console.error("Error retrieving session");
                reject(err);
            } else if (session.isValid()) {
                resolve(session);
            } else {
                console.error("Session is invalid");
                reject(new Error("Session is invalid"));
            }
        });
    });
};

// Initialize the S3 client with credentials from Cognito Identity Pool
const initializeS3Client = (region, userPoolId, token) => {
    const s3 = new S3Client({
        region,
        credentials: fromCognitoIdentityPool({
            client: new CognitoIdentityClient({ region }),
            identityPoolId: "eu-central-1:09e847b4-a62a-4ec7-91ec-3f93fd0ff22c",
            logins: {
                [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]: token
            }
        })
    });
    return s3;
};

// Safely parse JSON content
const parseJSON = (content) => {
    try {
        const parsedContent = JSON.parse(content);
        return parsedContent;
    } catch (error) {
        console.error("Failed to parse JSON content");
        throw new Error("Failed to parse JSON content");
    }
};

// Determine the bucket name based on the hostname
const getBucketNameFromUrl = () => {
    const hostname = window.location.hostname;
    let bucketSuffix = "compatibility";

    if (hostname.includes("test.sonda")) {
        bucketSuffix = "compatibility-testing";
    } else if (hostname.includes("dev.sonda") || hostname === "localhost") {
        bucketSuffix = "compatibility-dev";
    }

    const bucketName = `sonda-${bucketSuffix}`;
    return bucketName;
};

// Helper function to read from a ReadableStream
const streamToText = async (stream) => {    
    const reader = stream.getReader();
    const decoder = new TextDecoder("utf-8");
    let result = '';
    let done, value;
    while ({ done, value } = await reader.read(), !done) {
        result += decoder.decode(value, { stream: true });
    }
    result += decoder.decode();
    return result;
};

// Function to fetch compatible versions from S3
export const compatibleVersions = async (userPool) => {
    try {
        const userPoolId = userPool.getUserPoolId();
        const region = getRegionFromUserPoolId(userPoolId);
        const session = await getCurrentUserSession(userPool);
        const token = session.getIdToken().getJwtToken();

        // Initialize S3 client
        const s3 = initializeS3Client(region, userPoolId, token);
        const bucketName = getBucketNameFromUrl();
        const fileKey = "compatibility-matrix.json"; // Fixed file key
        const params = { Bucket: bucketName, Key: fileKey };

        // Fetch the object from S3
        const data = await s3.send(new GetObjectCommand(params));

        // Convert response to text
        let text;
        if (data.Body instanceof ReadableStream) {
            text = await streamToText(data.Body);
        } else if (data.Body instanceof Blob) {
            text = await blobToText(data.Body);
        } else if (Buffer.isBuffer(data.Body)) {
            text = bufferToText(data.Body);
        } else {
            throw new Error("Unsupported data.Body type");
        }

        // Parse and return JSON
        return parseJSON(text);
    } catch (error) {
        console.error("Error fetching the compatible versions");
        console.error("Error details:", error.message);
        throw error;
    }
};
